import sanitizeHtml from 'sanitize-html';
import { useMemo } from 'react';

export const useSanitizeHtml = (dirty, allowStyle = true) => {
	const allowedAttributes = useMemo(() => {
		const allowedAttributes = {
			a: ['href', 'name', 'target'],
			img: ['src'],
		};

		if (allowStyle) {
			allowedAttributes['*'] = ['style'];
		}

		return allowedAttributes;
	}, [allowStyle]);

	return useMemo(
		() =>
			sanitizeHtml(dirty, {
				allowedAttributes,
			}),
		[dirty, allowedAttributes]
	);
};

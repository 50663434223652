import * as React from 'react';
import PropTypes from 'prop-types';
import { useSanitizeHtml } from '../../hooks/sanitize-html';
import parseHtml from 'html-react-parser';
import classNames from 'classnames';

import * as styles from './parsed-content.module.scss';

const ParsedContent = ({ className, html, allowStyle }) => {
	const sanitizedHtml = useSanitizeHtml(html, allowStyle);

	return (
		<div
			className={classNames({
				[styles.parsedContent]: true,
				[className]: className,
			})}
		>
			{parseHtml(sanitizedHtml)}
		</div>
	);
};

ParsedContent.propTypes = {
	className: PropTypes.string,
	html: PropTypes.string.isRequired,
};

export default ParsedContent;
